import React from 'react'
import Layout from "../components/layout";


export default function privacy() {
    return (
        <Layout>
             <div className="w-full max-w-3xl p-4 mx-auto text-black text-center py-32">
                <p className="text-4xl mx-auto text-left mb-4 font-bold text-gray-800">Privacy</p>
                <div className="h-1 mx-auto gradient opacity-25 rounded"></div>
                <p className="text-2xl mx-auto text-left mt-4 text-gray-600">
                    Personal data from this form will be used to process your request and to contact you (Art 6 Para 1 lit b GDPR) and will be stored within the EU for the duration of the business relationship. Fields marked with "*" are mandatory, if you do not provide this information, your request cannot be processed. 
                    <br/>
                    <br/>
                    The test environment data is stored within the EU and automatically deleted 4 weeks after the end of the test period. The data is neither automatically processed nor analyzed nor passed on to third parties.
                    <br/>
                    <br/>
                    If the legal requirements are met, you have the following rights in accordance with the GDPR:
                    <br/>
                    Right to information, correction, deletion, restriction of processing, data transferability, objection to processing.
                    <br/>
                    <br/>
                    You have the right to complain to the following supervisory authority if you believe that the processing of your personal data is not lawful:
                    <br/>
                    Austrian Data Protection Authority, Barichgasse 40-42, 1030 Vienna, email: dsb@dsb.gv.at
                    <br/>
                </p>
            </div>
        </Layout>
    )
}
